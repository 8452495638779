@import '~/styles/styles';

.prod-tangibles {
  padding: 30px 0;
  text-align: center;
  display: grid;
  gap: 30px;
  justify-items: center;

  .tangible-items {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(5, 220px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 160px);
      gap: 10px;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .tangible-items-3 {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(3, 400px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 160px);
      gap: 10px;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .tangible-items-2 {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(2, 400px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 175px);
      gap: 10px;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .tangible-items-andy-drop {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(3, 150px);

    @include tablet {
      grid-template-columns: repeat(3, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(3, 115px);
      gap: 10px;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .tangible-videos {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(3, 400px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(1, 90%);
      gap: 10px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .yr-auction-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(1, 1400px);

    @include tablet {
      grid-template-columns: repeat(1, 700px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(1, 300px);
      gap: 10px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .yr-deck-card-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(2, 500px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 160px);
      gap: 10px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;
      margin-bottom: 30px;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .yr-launch {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(2, 600px);

    @include tablet {
      grid-template-columns: repeat(1, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(1, 300px);
      gap: 10px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: left;
      margin-bottom: 30px;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .yr-event-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(4, 300px);
    text-align: left;

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(1, 90%);
      gap: 50px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: left;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .berrics-spots-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(4, 300px);
    text-align: left;

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 50%);
      gap: 20px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: left;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .berrics-spots-2-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(2, 300px);
    text-align: left;

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 50%);
      gap: 20px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: left;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }

  .berrics-spots-3-columns {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(3, 300px);
    text-align: left;

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 50%);
      gap: 20px;
      justify-content: center;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: left;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}

.width {
  width: 50%;
}

.container-text {
  @include mobile {
    padding: 0 50px;
  }
}
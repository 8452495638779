@mixin debug($border-color, $bg-color: '') {
  outline: 2px solid $border-color;

  @if $bg-color {
    background-color: $bg-color;
  }
}

@mixin custom-breakpoint($start, $end: '') {
  @if $end != '' {
    @media screen and (width > #{$start} + px) and (width < #{$end} + px) {
      @content;
    }
  } @else {
    @media screen and (width < #{($start)} + px) {
      @content;
    }
  }
}

@mixin desktop-lg {
  @media screen and (width > #{$lg} + 1) {
    @content;
  }
}

@mixin desktop {
  @media screen and (width > #{$md} + 1) and (width < $lg) {
    @content;
  }
}

@mixin tablet {
  @media screen and (width < $md) {
    @content;
  }
}

@mixin mobile {
  @media screen and (width < $sm) {
    @content;
  }
}

@mixin background-img($image-path) {
  background-image: url($image-path);
  background-repeat: no-repeat;
  background-size: cover;
  @content;
}

@mixin underlined-link {
  position: relative;
  font-weight: 900;
  transition: all 500ms cubic-bezier(0.55, 0.27, 0.4, 0.89);

  &::after {
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: $gradient-rainbow;
  }

  &:hover,
  &.is-active {
    &::after {
      opacity: 1;
      transition-duration: 0.8s;
    }
  }
}

@mixin stagger-animation($element, $time, $element-count: 1) {
  @for $i from 1 through $element-count {
    #{$element}:nth-child(#{$i}) {
      transition-delay: $time * $i;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3600deg);
  }
}

@mixin animate-dot($name, $position) {
  @keyframes #{$name} {
    0% {
      box-shadow: $position 0px 0 -5px $white;
    }
    25% {
      box-shadow: $position 0px 0 1px $white;
    }
    65%,
    100% {
      box-shadow: $position 0px 0 -5px $white;
    }
  }

  animation: $name 1.4s linear infinite;
  box-shadow: $position 0 0 -5px $white;
  animation-delay: 200ms;
}

@import '~/styles/styles';

.header-main {
  display: grid;
  place-content: center;
  padding: 20px 0;

  @include mobile {
    padding: 0px 0;
  }
}

.header-container {
  display: flex;
  grid-gap: 60px;

  @include mobile {
    margin-bottom: 50px;
  }

  section {
    width: 720px;
    aspect-ratio: 16/9;
  }
}

.header-container-new-drop {
  display: flex;
  grid-gap: 60px;
  align-items: center;

  @include mobile {
    margin-bottom: 50px;
  }

  section {
    aspect-ratio: 16/9;
  }
}

.copy {
  display: flex;
  flex-direction: column;
  place-content: center;
  color: $white;
  max-width: 480px;

  @include mobile {
  }

  button {
    align-self: center;
  }

  h1 {
    text-align: center;
  }
}

@include custom-breakpoint(1200) {
  .header-container {
    flex-direction: column-reverse;
    grid-gap: 20px;
    align-items: center;
    padding-top: 30px;

    section {
      width: 75vw;
      height: unset;
      order: 2;

      @include mobile {
        width: 100%;
      }
    }

    .copy {
      order: 1;

      @include mobile {
        .container {
          padding: 0;

          input {
            margin-bottom: 0;
          }
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .header-container-new-drop {
    flex-direction: column-reverse;
    grid-gap: 20px;
    align-items: center;
    padding-top: 30px;

    section {
      width: 75vw;
      height: unset;
      order: 2;

      @include mobile {
        width: 100%;
      }
    }

    .copy {
      order: 1;

      @include mobile {
        .container {
          padding: 0;

          input {
            margin-bottom: 0;
          }
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .header-container-flipped {
    @include mobile {
      flex-direction: column !important;
    }
  }

  .mobile-padding-top {
    @include mobile {
      padding-top: 200px;
    }
  }

  .header-container {
    flex-direction: column-reverse;
    grid-gap: 20px;
    align-items: center;

    section {
      width: 75vw;
      height: unset;
      order: 2;

      @include mobile {
        width: 100%;
      }
    }

    .copy {
      order: 1;

      @include mobile {
        .container {
          padding: 0;

          input {
            margin-bottom: 0;
          }
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .header-container-new-drop {
    flex-direction: column-reverse;
    grid-gap: 20px;
    align-items: center;
    padding-top: 30px;

    section {
      width: 75vw;
      height: unset;
      order: 2;

      @include mobile {
        width: 100%;
      }
    }

    .copy {
      order: 1;

      @include mobile {
        .container {
          padding: 0;

          input {
            margin-bottom: 0;
          }
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.videos-container {
  display: flex;
  padding: 0 10px;
  max-width: 100vw;
  gap: 20px;
  @include custom-breakpoint(1200) {
    flex-direction: column-reverse;
  }

  div {
    margin: 0;
  }

  iframe {
    margin: 0;
  }
}

.youtube-embed {
  height: 90%;
  width: 100%;
}

.andy-premiere-embed {
  height: 500px;
  width: 100%;

  @include mobile {
    height: 200px;
  }
}

.twitch-embed {
  height: 100%;
  width: 100%;
}

.header-series-title {
  display: grid;
  grid-auto-flow: column;

  @include custom-breakpoint(1200) {
    grid-auto-flow: row;
  }
}

.background-gradient {
  background: linear-gradient(90deg, #b321de 0%, #9260ff 0.01%, #4544ff 101.49%);
  height: 190px;
  @include mobile {
    height: 190px;
  }
}

.yeah-right-bg-gradient {
  background: black;
  height: 220px;
  @include mobile {
    height: 205px;
  }
}

.yeah-right-countdown-header {
  background: black;
  height: 250px;
  @include mobile {
    height: 200px;
  }
}

.yeah-right-countdown-live {
  background: black;
  height: 260px;
  @include mobile {
    height: 200px;
  }
}

.width {
  width: 75%;
  margin: 0 auto;
}

.margin-auto {
  margin: 0 auto;
}

button.button-black {
  background: black !important;
}

.margin-20 {
  margin: 20px 0;
}

.margin-top-20 {
  margin-top: 20px;
}

.glitch-background {
  @include background-img($backgroundBigGlitch);
}

.berrics-background {
  @include background-img($berricsBackground);

  @include mobile {
    @include background-img($berricsBackgroundMobile);
  }
}

.berrics-map {
  @include background-img($berricsMap);
  height: 573px;

  @include mobile {
    height: 120px;
  }
}

.jeron-background {
  @include background-img($jeronDesktop);

  @include mobile {
    @include background-img($jeronMobile);
  }
}

.jeron-wisc-background {
  @include background-img($jeronWiscDesktop);

  @include mobile {
    @include background-img($jeronWiscMobile2);
  }
}

.teaser-background {
  @include background-img($teaserDesktop);

  @include mobile {
    @include background-img($teaserMobile);
  }
}

.garrett-background {
  @include background-img($garrettHillDesktopSmall);

  @include mobile {
    @include background-img($garrettHillMobile);
  }
}

.yuto-background {
  @include background-img($yutoDesktop);

  @include mobile {
    @include background-img($yutoMobile);
  }
}

.andy-background {
  @include background-img($andyDesktop);

  @include mobile {
    @include background-img($andyMobile);
  }
}

.mikemo-last-board-background {
  @include background-img($mikemoLastBoardDesktop);
  height: 600px;

  @include mobile {
    @include background-img($mikemoLastBoardMobile);
    height: 700px;
  }
}

.row-premiere {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;

  @include mobile {
    margin: 10px 0;
  }
}

.row-premiere img {
  width: 100%;
  height: auto;
}

.row-premiere p {
  margin-top: 10px;
  text-align: center;
  font-size: 1.2em;
  @include mobile {
    font-size: 1em;
  }
}